import {
  AccountCardMFEProps,
  LayoutVariant
} from 'account-card-mfe/lib/mfe/AccountCardMFE/AccountCardMFE.types'
import { MAPConfig } from 'make-a-payment'
import { AccountDetailsProps } from 'account-details-mfe'
import { getMFEConfigForMockState } from 'const/configMFE'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { analyticsEvents } from 'helpers/analytics/events'
import {
  cleanDashboardAnalytics,
  setOpenMFEAnalyticsDefaults
} from 'helpers/analytics/helpers'
import { DisplayOptionTypes } from 'mma-mfe/lib/src/ui/activity/activity-types'
import type { MFEDefaultAnalytics } from 'typings/Analytics'
import { useOrchestratorState } from 'orchestrator/Orchestrator'
import { EVENTS } from 'const/orchestratorMachine'
import useOrchestratorHelpers from 'orchestrator/OrchestratorHelpers'
import { OpenMFEEvent } from 'orchestrator/Orchestrator.types'
import { QUALTRICS_EVENTS } from 'const/qualtrics'
import { PlatformType } from 'alerts-mfe/const/alertsConfig'
import {
  accountDetailsModalContainerId,
  makeAPaymentModalContainerId
} from 'const/modalContainerIds'
import getMAPHelpPhoneNumber from 'helpers/getMAPHelpPhoneNumber'
import useQualtrics from './useQualtrics'
import useSettings from './useSettings'
import useFeatureFlags from './useFeatureFlags'
import useUser from './useUser'
import useiOSBodyScrollBlocker from './useiOSBodyScrollBlocker'
import useAccountInfo from './useAccountInfo'

const { OPEN_DETAILS, OPEN_ACTIVITY, OPEN_MAP } = EVENTS

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useMFEConfigs = () => {
  const { closeMFE, openMFE } = useOrchestratorHelpers()
  const {
    accountId: selectedAccountId,
    isMAPOpen,
    isActivityOpen,
    isDetailsOpen
  } = useOrchestratorState()
  const { currentLanguage, accountIds } = useUser()
  const { isMockDataEnabled } = useSettings()
  const { reloadQualtrics } = useQualtrics()
  const mfeConfigToUse = getMFEConfigForMockState(isMockDataEnabled)
  const { enableBodyScroll, disableBodyScroll } = useiOSBodyScrollBlocker()
  const {
    accountDetails: accountDetailsFlags,
    accountCard: accountCardFlags,
    makeAPayment: makeAPaymentFlags
  } = useFeatureFlags()
  const currentAccountData = useAccountInfo(selectedAccountId)

  const handleMFEClose = () => {
    triggerAnalyticsEvent(
      analyticsEvents.mfeCloseAndBackToDashboard(accountIds)
    )
    cleanDashboardAnalytics()
    enableBodyScroll()
    closeMFE()
  }

  const handleMFEOpen = (
    accountId: string,
    mfeName: OpenMFEEvent,
    analytics: MFEDefaultAnalytics
  ) => {
    openMFE(mfeName, accountId)
    disableBodyScroll()
    setOpenMFEAnalyticsDefaults(
      analytics.ClientName,
      analytics.Product,
      accountIds.length
    )
  }
  return {
    getMAPConfig: (): MAPConfig => ({
      ...mfeConfigToUse,
      accountId: selectedAccountId,
      isOpen: isMAPOpen,
      id: `make-a-payment-${selectedAccountId}`,
      containerId: makeAPaymentModalContainerId(selectedAccountId),
      featureFlags: makeAPaymentFlags,
      helpNumber: getMAPHelpPhoneNumber(currentAccountData?.product.type),
      handleClose: () => {
        handleMFEClose()
        reloadQualtrics()
      },
      triggerAnalyticsEvent
    }),

    getAccountDetailsConfig: (): AccountDetailsProps => ({
      config: {
        ...mfeConfigToUse,
        lang: currentLanguage
      },
      handleClose: handleMFEClose,
      selectedAccountId,
      isModalOpen: isDetailsOpen,
      renderQueryClientProvider: false,
      triggerAnalyticsEvent,
      containerId: accountDetailsModalContainerId(selectedAccountId),
      featureFlags: accountDetailsFlags
    }),

    getAccountCardConfig: ({
      accountId,
      analytics,
      variant
    }: {
      accountId: string
      analytics: MFEDefaultAnalytics
      variant?: LayoutVariant
    }): AccountCardMFEProps => {
      const eventHandlers = {
        handleActivityClick: () =>
          handleMFEOpen(accountId, OPEN_ACTIVITY, analytics),
        handleDetailsClick: () =>
          handleMFEOpen(accountId, OPEN_DETAILS, analytics),
        handleMAPClick: () => {
          handleMFEOpen(accountId, OPEN_MAP, analytics)
          reloadQualtrics(QUALTRICS_EVENTS.OPEN_MAP)
        },
        handleRewardsClick: () => {}
      }
      return {
        accountId,
        mfeConfig: mfeConfigToUse,
        variant,
        eventHandlers,
        isQueryClientPresent: true,
        isViewAccountDisabled: false,
        featureFlags: accountCardFlags
      }
    },

    getActivityConfig: () => ({
      handleClose: () => handleMFEClose(),
      isModalOpen: isActivityOpen,
      config: mfeConfigToUse,
      display: 'condensed' as DisplayOptionTypes,
      guidAccountId: selectedAccountId
    }),

    getAlertsConfig: () => ({
      config: mfeConfigToUse,
      alertType: 'all',
      platform: PlatformType.PEAKS,
      accountSummaryKeys: accountIds.map((accountId) => {
        return { accountId, accountIdType: 'guid' }
      })
    }),

    getCardActivationConfig: () => mfeConfigToUse
  }
}

export default useMFEConfigs
