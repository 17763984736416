import { LanguageObject } from './typings'

const common = {
  creditCardError: 'Ingrese el número completo de la tarjeta de crédito',
  ssnPlaceholder: 'Últimos 4 Dígitos del SSN',
  ssnInvalidError: 'Los últimos 4 dígitos del SSN son requeridos',
  ssnEmptyError: 'Ingrese los últimos 4 dígitos del SSN',
  zipCodePlaceholder: 'Código Postal',
  zipCodeEmptyError: 'Ingrese un código postal'
}

const es: LanguageObject = {
  labels: {
    accountEndingIn: 'Cuenta que termina en',
    activity: 'Actividad Reciente',
    loading: 'Buscando tu cuenta',
    loanEndingIn: 'Préstamo que termina en',
    waivedInterestEndingIn: 'Préstamo con Interés Eximido que termina en',
    securedInstallmentEndingIn: 'Préstamo en Cuotas Asegurado que termina en'
  },
  errors: {
    littleTrouble:
      'Lo sentimos, estamos teniendo algunas dificultades en este momento.',
    technicalError:
      'Nuestro sitio está teniendo algunas dificultades técnicas. Por favor intente nuevamente más tarde.',
    pageNotFound: 'Parece que no podemos encontrar lo que está buscando.',
    pageNotFoundImgAlt: 'página no encontrada',
    sorryPage: 'Lo sentimos, algo salió mal',
    maintenanceMessage:
      'Lo sentimos, estamos temporalmente no disponibles por mantenimiento.',
    supportText: 'Por favor vuelva a intentar más tarde o visite '
  },
  errorComponent: {
    header: 'Nos Disculpamos',
    sorrySomethingWrong: 'Lo sentimos, algo salió mal',
    cantFindIt: 'Parece que no podemos encontrar lo que está buscando',
    technicalDifficulties:
      'Estamos experimentando dificultades técnicas en este momento y no podemos accesar a su cuenta. Por favor, intente más tarde.',
    blockedImgAlt: 'Persona que sostiene un símbolo tachado',
    catDisconnectedImgAlt: 'Gato y cable eléctrico desconectado',
    contactCustomerService:
      'Debido a un problema con su cuenta, no podrá iniciar sesión en este momento. Comuníquese con Servicio al Cliente al (844) 373-4960',
    troubleLoggingIn: 'Estamos teniendo problemas iniciando su sesión',
    sleepyCatImgAlt: 'Gato durmiendo',
    noActiveAccounts:
      'No tenemos ninguna cuenta suya que se encuentre activa en estos momentos.',
    noActiveAccountsCustomerService:
      'Si usted considera que esto es un error, comuníquese con atención al cliente al (844) 373-4960.',
    unableToSignInAccountStatus:
      'No se puede iniciar sesión debido al estado de la cuenta',
    securedAccountCustomerService:
      'Llame al Servicio de Atención al Cliente (866) 220-9432 para hablar de su cuenta o efectuar un pago.',
    logoutImgAlt: 'Persona saliendo por una puerta',
    signOut: 'Cerrar Sesión',
    useButtonBelow:
      'Use el botón que está a continuación para regresar a la página de inicio e iniciar sesión de nuevo.',
    backToSignIn: 'Regresar a Iniciar Sesión',
    signOutSuccessful: 'Cerraste la sesión con éxito',
    seeYouLater: 'Nos vemos a la próxima'
  },
  personalGreeting: {
    morning: 'Buenos Días',
    afternoon: 'Buenas Tardes',
    evening: 'Buenas Noches',
    signOut: 'Hasta luego'
  },
  footer: {
    body: 'Sus cuentas de crédito son emitidas por Synchrony Bank. La Política de Privacidad de Synchrony Bank rige el uso sus cuentas de crédito. El uso de este sitio se rige por el uso de la Política de Privacidad de Internet de Synchrony Bank. Es posible que el contenido y los documentos que se muestran pueden no se proporcionen en español.',
    privacyPolicy: 'Política de Privacidad en Línea',
    privacyPolicyLink: 'legal/privacy-policy-sp',
    usageAgreement: 'Acuerdo de uso en Línea',
    usageAgreementLink: 'legal/terms-of-use-sp',
    fraudProtection: 'Protección contra Fraude',
    fraudProtectionlink: 'legal/fraud-protection-sp'
  },
  loginCard: {
    title: 'Acceder al préstamo',
    signInBtn: 'Iniciar Sesión',
    manageCardBtn: 'Administre una Tarjeta de Crédito',
    or: 'O',
    mobilePlaceholder: 'Número de celular',
    mobileHelper: 'El mismo número que se usó en su solicitud',
    mobileError: 'El número de teléfono móvil debe tener 10 dígitos.',
    ssnPlaceholder: common.ssnPlaceholder,
    ssnError: 'Los Últimos Cuatro números del SSN deben ser 4 dígitos',
    ssnHelpText: 'SSN: Número de Seguro Social',
    authErrorTitle: 'Verifique su información',
    authErrorDescription:
      'No reconocemos la combinación de este número de teléfono móvil y el número de seguro social. Por favor, intente de nuevo.',
    bannerSynchronyBrandName: 'Synchrony Pay Later',
    bannerBody:
      ' ha mejorado la imagen y sensación de la experiencia de servicio de su cuenta con la misma funcionalidad.'
  },
  revolvingCardSignIn: {
    title: 'Acceder a la tarjeta de crédito',
    creditCardPlaceholder: 'Tarjeta de crédito',
    creditCardError: common.creditCardError,
    ssnPlaceholder: 'Los Últimos 4 Dígitos del SSN o ITIN',
    ssnHelpText: 'SSN: Número de Seguro Social, ITIN: Tax ID',
    ssnError: 'Ingrese los últimos 4 dígitos de su SSN o ITIN',
    zipCodePlaceholder: common.zipCodePlaceholder,
    zipError: 'Ingrese su código postal',
    manageALoan: 'Administre un Préstamo',
    or: 'o'
  },
  securedInstallmentsSignIn: {
    accountNumberPlaceholder: 'Número de cuenta',
    accountNumberEmptyError: 'Ingrese un número de cuenta',
    accountNumberInvalidError: 'Verifique el número de cuenta',
    signInAuthError:
      'La información que ingresó no coincide con la nuestra. Si necesita ayuda, llame a atención al cliente al (866) 220-9432.',
    ssnPlaceholder: common.ssnPlaceholder,
    ssnHelpText: 'SSN: Número de Seguro Social',
    ssnEmptyError: common.ssnEmptyError,
    ssnInvalidError: common.ssnInvalidError,
    zipCodePlaceholder: common.zipCodePlaceholder,
    zipCodeEmptyError: common.zipCodeEmptyError,
    zipCodeInvalidError: 'El código postal debe tener 5 dígitos'
  },
  universalActivation: {
    title: 'Active su tarjeta',
    subtitle:
      'Solo necesitamos un poco más de información para completar su activación',
    creditCardPlaceholder: 'Número de Tarjeta de Crédito',
    creditCardHelpText: 'Ingrese el número completo de la tarjeta de crédito',
    creditCardEmptyError: common.creditCardError,
    creditCardIncompleteError: 'Debe tener 16 dígitos',
    securityCodePlaceholder: 'Código de seguridad',
    securityCodeHelpText: 'Código de seguridad en la tarjeta',
    securityCodeEmptyError: 'Ingrese el código de seguridad en la tarjeta',
    securityCodeIncompleteError: 'Debe tener 3 dígitos',
    ssnPlaceholder: common.ssnPlaceholder,
    ssnHelpText: 'Ingrese los últimos 4 dígitos del SSN',
    ssnEmptyError: common.ssnEmptyError,
    ssnInvalidError: common.ssnInvalidError,
    zipCodePlaceholder: common.zipCodePlaceholder,
    zipCodeHelpText: 'Código Postal de 5 o 9 dígitos',
    zipCodeEmptyError: common.zipCodeEmptyError,
    zipCodeIncompleteError: 'El código postal debe tener 5 o 9 dígitos',
    continueButton: 'Continuar',
    cancelButton: 'Cancelar'
  },
  inactivityModal: {
    modalTitle: 'La sesión ha expirado',
    promptTitle: '¿Sigue allí?',
    countdownWarning: 'Su sesión en línea expirará en',
    continueButton: 'SIGO AQUÍ',
    logOffButton: 'CERRAR SESIÓN',
    seconds: 'segundos.'
  },
  mfeErrorModal: {
    modalTitle:
      'Lo sentimos, esta funcionalidad no se encuentra disponible por el momento',
    content: 'Por favor intente más tarde.'
  },
  buttons: {
    signOut: 'Cerrar Sesión',
    close: 'Cerrar',
    back: 'Atrás'
  },
  /**
   * When the user is on an Spanish page, the language button is going to say "English".
   * For people using a screen reader, they need to be able to hear, in English, what the button is supposed to do.
   */
  transparentHeader: {
    languageAriaLabel: 'Change Language To English'
  },
  header: {
    synchronyLogoImgAlt: 'Logo de Synchrony'
  }
}

export default es
