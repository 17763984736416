import { ReactElement } from 'react'
import { i18n } from 'strings/i18n'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import Card from 'syf-component-library/ui/atoms/Card/Card'
import { H4 } from 'syf-component-library/ui/typography'
import getImageURL from 'helpers/getImageURL'
import { useUser } from 'hooks'
import {
  CardImage,
  Container,
  InnerContainer,
  MaintenancePageHeader,
  SupportText,
  TextContainer
} from './MaintenancePage.styles'

const MaintenancePage = (): ReactElement => {
  // force footer to rerender on language change, that's stored in the user context
  useUser()
  const maintenancePageImage = getImageURL('sleepy-cat')
  const contactUsUrl = 'https://www.synchrony.com/help/contact-us'

  return (
    <Container>
      <InnerContainer>
        <Stack size="large" respondToBreakpoints={['small']} />
        <Card>
          <MaintenancePageHeader>
            <CardImage src={maintenancePageImage} alt="sleepy cat" />
          </MaintenancePageHeader>
          <TextContainer>
            <H4 weight="bold">{i18n({ errors: 'maintenanceMessage' })}</H4>
            <Inset top="medium" />
            <SupportText>
              {i18n({ errors: 'supportText' })}
              <span>
                <a href={contactUsUrl}>{contactUsUrl}</a>
              </span>
            </SupportText>
            <Stack size="large" />
          </TextContainer>
        </Card>
      </InnerContainer>
    </Container>
  )
}

export default MaintenancePage
