import { activateCardForPreLogin } from 'card-actions-mfe/helpers'
import { useMFEConfigs } from 'hooks'
import { useEffect, useState } from 'react'
import { Button } from 'syf-component-library/ui/atoms'
import Grid from 'syf-component-library/ui/patterns/Grid'
import MessageBox from 'syf-component-library/ui/patterns/MessageBox'
import LoadingView from 'ui/molecules/LoadingView'
import { ICONS } from 'const/icons'
import ActivationSuccessAdapter from '../ActivationSuccessAdapter'

const ActivationHandlerAdapter = () => {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [productDisplayName, setProductDisplayName] = useState('')
  const { getCardActivationConfig } = useMFEConfigs()
  const config = getCardActivationConfig()

  const activateCard = () => {
    setIsLoading(true)
    // adding timeout here to visualize the loading screen
    setTimeout(async () => {
      try {
        // adding timeout here to visualize loading view
        const result = await activateCardForPreLogin(config)
        setProductDisplayName(result?.productDisplayName)
        setIsLoading(false)
      } catch (error) {
        setIsError(true)
        setIsLoading(false)
      }
    }, 2000)
  }

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('card_activation_failed')
    }
  }, [])
  /*
   * Using two buttons for now to simulate, but in reality the activateCard function should be called when the component is called (useEffect maybe)
   * TBD: not use any button, we just going to make the call to activateCardForPreLogin and these buttons should be removed
   */
  const mockSuccessActivation = () => {
    // using session storage to see both scenarios
    setIsError(false)
    sessionStorage.setItem('card_activation_failed', 'false')
    activateCard()
  }

  const mockFailedActivation = () => {
    setProductDisplayName('')
    sessionStorage.setItem('card_activation_failed', 'true')
    activateCard()
  }

  return (
    <>
      {isLoading ? (
        <LoadingView showText={false} />
      ) : (
        <Grid
          direction="column"
          justifyContent="center"
          desktopGap={{ rowGap: '8px' }}
        >
          <Grid>
            <Button buttonType="primary" onClick={mockSuccessActivation}>
              Test Success
            </Button>
            <Button buttonType="primary" onClick={mockFailedActivation}>
              Test Failure
            </Button>
          </Grid>

          <Grid>
            {productDisplayName && (
              <ActivationSuccessAdapter productName={productDisplayName} />
            )}
            {isError && (
              // TBD: Use the ActivationErrorAdapter for this case, perhaps in ErrorBoundary
              <MessageBox type="error" icon={ICONS.EXCLAMATION_TRIANGLE}>
                There was an error when activating your card
              </MessageBox>
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ActivationHandlerAdapter
