import ActivationSuccess from 'card-actions-mfe/universal-activation/activation-success'
import useMFEConfigs from 'hooks/useMFEConfigs'

const ActivationSuccessAdapter = ({ productName }: { productName: string }) => {
  const { getCardActivationConfig } = useMFEConfigs()
  const config = getCardActivationConfig()
  return <ActivationSuccess config={config} productDisplayName={productName} />
}

export default ActivationSuccessAdapter
