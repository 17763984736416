import { setAppSessionItem } from 'helpers/appSessionItem'

export const isValidLength = (value: string, length: number): boolean =>
  value.length === length

export const doesValueExistInObj = (
  object: Record<string, unknown>,
  value: string
): boolean => {
  return Object.values(object).includes(value)
}

export const refreshChunk = async (
  componentImport,
  chunkName: string
): Promise<never> =>
  new Promise((resolve, reject) => {
    const storageKey = `refreshed-${chunkName}`
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(storageKey) || 'false'
    )
    componentImport()
      .then((component) => {
        if (component === undefined) {
          if (window.sessionStorage.getItem(storageKey) === 'true') {
            resolve(undefined)
          }
          setAppSessionItem(storageKey, 'true')
          window.location.reload() // refresh the page
        }
        resolve(component)
      })
      .catch((error: object) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true')
          window.location.reload()
        }
        reject(error) // Default error behaviour as already tried refresh
      })
  })
