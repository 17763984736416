import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { Stack } from 'syf-component-library/ui/spacing'
import getErrorCaseFromError from 'helpers/getErrorCaseFromError'
import { HOME_PAGE } from 'const/config'
import { useQualtrics, useUser } from 'hooks'
import handleLogout from 'helpers/handleLogout'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { analyticsEvents } from 'helpers/analytics/events'
import { IOS_SAFARI_MODULE_IMPORT_ERROR } from 'const/moduleErrors'
import LoadingView from '../LoadingView'
import { ErrorContainer, InnerContainer } from './ErrorComponent.styles'
import type { ErrorComponentProps } from './ErrorComponent.types'
import PersonalGreeting from '../PersonalGreeting'
import ErrorCard from '../ErrorCard'
import ErrorHeader from '../ErrorHeader/ErrorHeader'

const ErrorComponent = ({ error }: ErrorComponentProps): ReactElement => {
  const { currentLanguage } = useUser()
  const { reloadQualtrics } = useQualtrics()

  useEffect(() => {
    reloadQualtrics()
  }, [currentLanguage])

  const renderErrorCard = (errorType: string): ReactElement => {
    const handleClick = () => {
      window.location.replace(HOME_PAGE)
    }

    const handleLogoutClick = async () => {
      triggerAnalyticsEvent(analyticsEvents.logoutClick())
      await handleLogout()
    }

    switch (errorType) {
      case 'no_active_accounts':
        return (
          <ErrorCard
            image="sleepy-cat"
            altText="sleepyCatImgAlt"
            bodyTitle="noActiveAccounts"
            bodyContent="noActiveAccountsCustomerService"
            centerButtonText="signOut"
            centerButtonClick={handleLogoutClick}
            currentLanguage={currentLanguage}
          />
        )
      case 'idp.authenticate.login.error.csr3': // high risk/iovation denied
        return (
          <ErrorCard
            image="blocked"
            altText="blockedImgAlt"
            bodyTitle="troubleLoggingIn"
            bodyContent="contactCustomerService"
            currentLanguage={currentLanguage}
          />
        )
      case 'idp.authenticate.account.status.error':
        return (
          <ErrorCard
            image="sleepy-cat"
            altText="sleepyCatImgAlt"
            bodyTitle="unableToSignInAccountStatus"
            bodyContent="securedAccountCustomerService"
            currentLanguage={currentLanguage}
          />
        )
      case 'idp.error.clientid':
      case 'idp.interaction.not.found':
      case 'converting.auth_code':
      case 'token.invalid':
      case 'invalid_session':
      case 'Error finishing authorization, no auth code for tokenizing':
        return (
          <ErrorCard
            image="cat"
            altText="catDisconnectedImgAlt"
            bodyTitle="troubleLoggingIn"
            bodyContent="technicalDifficulties"
            centerButtonText="backToSignIn"
            centerButtonClick={handleClick}
            currentLanguage={currentLanguage}
          />
        )
      case IOS_SAFARI_MODULE_IMPORT_ERROR:
        return <LoadingView showText={false} />
      case '400': // bad request
      case '401': // unauthorized
        window.location.replace(HOME_PAGE)
        break
      case '403': // forbidden
      case '404': // not found
      case '405': // method not allowed
      case '415': // unsupported media type
      case '422': // unprocessable entity
      case '500': // internal server error
      case '502': // bad gateway
      case '503': // service unavailable
      case '504': // gateway timeout
        return (
          <ErrorCard
            image="cat"
            altText="catDisconnectedImgAlt"
            bodyTitle="header"
            bodyContent="technicalDifficulties"
            currentLanguage={currentLanguage}
          />
        )
      default: // unknown errors
        return (
          <ErrorCard
            image="cat"
            altText="catDisconnectedImgAlt"
            bodyTitle="sorrySomethingWrong"
            bodyContent="cantFindIt"
            currentLanguage={currentLanguage}
          />
        )
    }
  }

  const errorCase = getErrorCaseFromError(error)
  const hideGreeting = [
    IOS_SAFARI_MODULE_IMPORT_ERROR,
    'idp.authenticate.account.status.error'
  ].includes(errorCase)

  return (
    <>
      <ErrorHeader />
      {hideGreeting ? null : <PersonalGreeting />}
      <ErrorContainer>
        <InnerContainer aria-label="error page">
          <Stack size="large" respondToBreakpoints={['small']} />
          {renderErrorCard(errorCase)}
        </InnerContainer>
      </ErrorContainer>
    </>
  )
}

export default ErrorComponent
