/* eslint-disable @typescript-eslint/no-unused-vars */
import normalizeHost from 'helpers/normalizeHost'
import routes from './routes'
import { configHost } from './session'

export const ENVS = {
  dev: 'development',
  prod: 'production',
  local: 'local'
} as const
// fancy ts trick to derive the types from a constants object
export type ENV = (typeof ENVS)[keyof typeof ENVS]

export type REGION = 'prod' | 'dev' | 'qa' | 'uat' | 'localhost'
export type IVA = 'ivaqa' | 'iva'

export const {
  VITE_APP_REGION,
  VITE_APIGEE_CLIENT_ID_LOCAL,
  VITE_APIGEE_ACTIVATION_CLIENT_ID_LOCAL,
  VITE_MOCK_ACCESS_TOKEN,
  VITE_MOCK_ID_TOKEN,
  PUBLIC_URL,
  PROD,
  DEV
} = import.meta.env
// string used as app root by the proxy in UI-Reverse-Proxies repo
const UI_PROXY_BASENAME = 'react-apps'
const APP_REGION = 'CF_APP_REGION' as REGION
// environment variable defined in manifest-[ENVIRONMENT].yml files, then aliased/replaced in pcf.sh, not available in local
const APP_ENV = 'CF_APP_ENV' as ENV
// dev is using a different base url so we need to check which env is being deployed. Need to check this way to trick the compiler
const isDevRegion = ['dev'].includes(APP_REGION)
const BASENAME =
  isDevRegion && window.location.href.includes(UI_PROXY_BASENAME)
    ? `${UI_PROXY_BASENAME}/quickaccess`
    : 'quickaccess'
export const HOME_PAGE = `${window.location.origin}/${BASENAME}`
const LOGIN_PAGE = `${HOME_PAGE}/${routes.LOGIN_LOAN}/`
const LOGIN_PAGE_ACTIVATION = `${HOME_PAGE}/${routes.UNIVERSAL_ACTIVATION}/`
const CALLBACK_PAGE = `${HOME_PAGE}/${routes.GET_TOKEN}/`
export const SIGNOUT_PAGE = `${HOME_PAGE}/${routes.SIGNOUT}/`
export const QA_API_HOST = 'https://api-qa.syf.com'
export const DEV_API_HOST = 'https://api-dev.prvcld.syfbank.com'
export const sandboxApi = 'https://api-sandbox.syf.com' // arbitrary sandbox URL
const IVA_HOST = 'CF_IVA_HOST' as IVA

const LOCAL_HOST = 'http://localhost:3000'
export const MOCK_ENABLED_HOSTS = [
  'localhost',
  'dev.synchronycredit.com',
  'qa.synchronycredit.com'
]
export const MOCK_ENABLED_APP_ENVS = ['local', 'development']

export const DEV_GPSHOPPER_ASSETS_URL =
  'https://dev.synchronycredit.com/assets/'
export const PROD_GPSHOPPER_ASSETS_URL =
  'https://images.synchronycredit.com/assets/'

export const APPS_GPSHOPPER_DIRECTORY = {
  account_card: 'account-card/',
  peaks: 'peaks/',
  synchrony: 'synchrony/'
}

export interface ConfigType {
  /** Whether we are in a prod or nonprod instance of the app, set in the manifest ymls */
  APP_ENV: ENV
  /** Base domain for making auth api requests to, something like 'https://auth-qa.syf.com'{ENDPOINT HERE} */
  AUTH_DOMAIN: string
  /** The app homepage, path after the root domain ie https://example.com/{THIS PART} */
  BASENAME: URL['pathname']
  /** Base url for all regular api requests */
  API_HOST: URL['origin']
  /** Where to redirect user to enter login username, password if they haven't logged in, must match apigee settings for loginUrlPattern  */
  TARGET_ORIGIN: URL['href']
  /** Where to redirect user to fill out activation form if unauthenticated, must match loginUrlPattern in apigee app attributes */
  TARGET_ORIGIN_ACTIVATION: URL['href']
  /** The apigee client id in the syf developer portal for this app's workspace sent as SYF-Client-Id */
  APIGEE_CLIENT_ID: string
  /** The apigee client id for the Peaks activation channel sent as X-SYF-API-Key */
  APIGEE_ACTIVATION_CLIENT_ID: string
  /** Base url for where to get assets from like images */
  ASSETS_HOST: URL['origin']
  /** Where to redirect user after login credential authentication is done so we can get a token */
  CALLBACK_PAGE: URL['href']
  /** The local host root domain used for local development */
  LOCAL_HOST: URL['origin']
  /** The app region, set in the manifest ymls */
  APP_REGION: REGION
  /** Host domain to load IVA scripts from */
  IVA_HOST: IVA
}

const prodConfig: ConfigType = {
  APP_ENV,
  AUTH_DOMAIN: 'CF_AUTH_DOMAIN',
  BASENAME,
  API_HOST: 'CF_API_HOST',
  TARGET_ORIGIN: LOGIN_PAGE,
  TARGET_ORIGIN_ACTIVATION: LOGIN_PAGE_ACTIVATION,
  CALLBACK_PAGE,
  APIGEE_CLIENT_ID: 'CF_APIGEE_CLIENT_ID',
  APIGEE_ACTIVATION_CLIENT_ID: 'CF_APIGEE_ACTIVATION_CLIENT_ID',
  ASSETS_HOST: 'CF_ASSETS_HOST',
  LOCAL_HOST: '',
  APP_REGION,
  IVA_HOST
}

const reverseProxyConfig: ConfigType = {
  APP_ENV,
  AUTH_DOMAIN: 'CF_AUTH_DOMAIN',
  BASENAME,
  API_HOST: 'CF_API_HOST',
  TARGET_ORIGIN: LOGIN_PAGE,
  TARGET_ORIGIN_ACTIVATION: LOGIN_PAGE_ACTIVATION,
  CALLBACK_PAGE,
  APIGEE_CLIENT_ID: 'CF_APIGEE_CLIENT_ID',
  APIGEE_ACTIVATION_CLIENT_ID: 'CF_APIGEE_ACTIVATION_CLIENT_ID',
  ASSETS_HOST: 'CF_ASSETS_HOST',
  LOCAL_HOST,
  APP_REGION,
  IVA_HOST
}

const pcfConfig: ConfigType = {
  APP_ENV,
  AUTH_DOMAIN: 'CF_AUTH_DOMAIN',
  BASENAME: '',
  API_HOST: 'CF_API_HOST',
  TARGET_ORIGIN: LOGIN_PAGE,
  TARGET_ORIGIN_ACTIVATION: LOGIN_PAGE_ACTIVATION,
  CALLBACK_PAGE,
  APIGEE_CLIENT_ID: 'CF_APIGEE_CLIENT_ID',
  APIGEE_ACTIVATION_CLIENT_ID: 'CF_APIGEE_ACTIVATION_CLIENT_ID',
  ASSETS_HOST: 'CF_ASSETS_HOST',
  LOCAL_HOST,
  APP_REGION,
  IVA_HOST
}

const localConfig: ConfigType = {
  APP_ENV: 'local',
  // use dev when hotfixes that aren't released in qa yet that currently block auth-qa from working
  /* AUTH_DOMAIN: DEV_API_HOST */
  AUTH_DOMAIN: 'https://auth-qa.syf.com',
  BASENAME,
  API_HOST: QA_API_HOST,
  // must force a synchrony credit domain hosted page bc localhost is not allowed for login urls
  TARGET_ORIGIN: `https://qa.synchronycredit.com/quickaccess/${routes.LOCAL_LOGIN}/`,
  TARGET_ORIGIN_ACTIVATION: `https://qa.synchronycredit.com/quickaccess/${routes.LOCAL_ACTIVATE}/`,
  CALLBACK_PAGE: `https://qa.synchronycredit.com/quickaccess/${routes.LOCAL_GET_TOKEN}/`,
  APIGEE_CLIENT_ID: VITE_APIGEE_CLIENT_ID_LOCAL,
  APIGEE_ACTIVATION_CLIENT_ID: VITE_APIGEE_ACTIVATION_CLIENT_ID_LOCAL,
  ASSETS_HOST: DEV_GPSHOPPER_ASSETS_URL,
  LOCAL_HOST,
  APP_REGION: VITE_APP_REGION as REGION,
  IVA_HOST: 'ivaqa'
}

/**
 * Get the corresponding config object for the current window location
 * Mainly for differentiating qa and localhost configs because
 * NODE_ENV is always production for all deployed enviornments.
 * @param host the hostname part of the current page url
 * @returns a corresponding ConfigType for the environment
 */
export const getConfigForLocation = (
  host: Location['hostname']
): ConfigType => {
  const formattedHost = normalizeHost(host)
  // to aid debugging the config used in deployed environments
  sessionStorage.setItem(configHost, formattedHost)

  switch (formattedHost) {
    case 'localhost':
      return localConfig
    case 'synchronycredit.com':
    case 'mysynchrony.com':
    case 'pcs.mysynchrony.com':
    case 'synchrony.com':
    case 'pvapp.synchronycredit.com':
      return prodConfig
    case 'dev.synchronycredit.com':
    case 'qa.synchronycredit.com':
    case 'uat.synchronycredit.com':
      return reverseProxyConfig
    case 'peaks-uat.app.dev2.dal.pcf.syfbank.com':
    case 'peaks-qa.app.dev2.dal.pcf.syfbank.com':
    case 'ecom-ui-peaks.app.dev2.dal.pcf.syfbank.com':
    case 'peaks.app.dev3.dal.pcf.syfbank.com':
      return pcfConfig
    default:
      console.warn('No cases matched for config, using default.')
      return reverseProxyConfig
  }
}

const config = getConfigForLocation(window.location.hostname)

export default config
