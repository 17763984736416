export const MOCK_AUTH = 'Mock Authorize'
export const MOCK_AUTHENTICATE_IOVATION = 'Authenticate Iovation Error'
export const MOCK_SESSION_INVALID = 'session_invalid'
export const MOCK_SESSION_NOT_FOUND = 'session_not_found'
export const MOCK_TOKEN_SERVICE_ERROR = 'token_service_error'
export const MOCK_INVALID_TOKEN = 'invalid_token'
export const MOCK_EXPIRED_TOKEN = 'expired_token'
export const MOCK_CLIENT_ERROR = 'client_error'
export const MOCK_AUTH_POST_ERROR = 'auth_post_error'
export const MOCK_ACCOUNT_NOT_FOUND = 'account_not_found'
export const MOCK_THROW_MFE = 'mock_throw_mfe'
export const MOCK_AUTOPAY_ENROLLED = 'autopay enrolled'
export const MOCK_NO_ACTIVE_ACCOUNTS = 'no_active_accounts'
export const MOCK_CARDS_LIMIT = 'cards_limit'
export const MOCK_ENABLED = 'mockDataEnabled'
export const MOCK_BLOCKED_ACCOUNT = 'account_blocked'
