import React, { Suspense } from 'react'
import type { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fontSizes } from 'syf-component-library/defaultTheme'
import { colors } from 'theme'

interface IconsProps {
  ALERTS: ReactElement
  SIGNOUT: ReactElement
  GLOBE: ReactElement
  TIMES: ReactElement
  ANGLEDOWN: ReactElement
  ANGLELEFT: ReactElement
  USER: ReactElement
  LOCK: ReactElement
  EXCLAMATION: ReactElement
  CREDIT_CARD: ReactElement
  MOBILE: ReactElement
  EXCLAMATION_TRIANGLE: ReactElement
  SPINNER: ReactElement
  BUILDING_COLUMNS: ReactElement
}

interface IconAttrs {
  'data-analytics'?: string
  'data-reason'?: string
  'data-object'?: string
  'data-type'?: string
  fontSize?: string
}

const getIconArgs = (iconName: string) => {
  switch (iconName) {
    case 'bell':
      return {
        'data-analytics': 'clicktrackingevent',
        'data-reason': 'manage alerts',
        'data-object': 'button',
        'data-type': 'manage alerts'
      }
    case 'lock':
    case 'credit-card':
    case 'mobile':
    case 'building-columns':
      return { fontSize: `${fontSizes.medium}px` }
    case 'globe':
      return { fontSize: `${fontSizes.large}px` }
    case 'spinner':
      return { color: colors.grey }
    default:
      return {}
  }
}

/**
 * This icons are imported async via React lazy load + suspense strategy,
 * in order to split the code to a different bundle and reduce loading times.
 * https://legacy.reactjs.org/docs/code-splitting.html.
 * Webpack magic comments are used (https://webpack.js.org/api/module-methods/#magic-comments)
 * to specify a different chunk name for them.
 */
const lazyIconImport = ({ definition }) => ({
  default: () => {
    const IconArgs: IconAttrs = getIconArgs(definition.iconName)
    // to prevent icons font-size change. IVA is setting all icons font-size to inherit
    return (
      <span style={{ fontSize: IconArgs.fontSize }}>
        <FontAwesomeIcon icon={definition} {...IconArgs} />
      </span>
    )
  }
})

const AlertsIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faBell`
  ).then(lazyIconImport)
)

const SignOutIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faSignOut`
  ).then(lazyIconImport)
)

const TimesIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faTimes`
  ).then(lazyIconImport)
)

const GlobeIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faGlobe`
  ).then(lazyIconImport)
)

const UserIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-solid-svg-icons/faUserCircle`
  ).then(lazyIconImport)
)

const BuildingColumnsIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faBuildingColumns`
  ).then(lazyIconImport)
)

const AngleLeftIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faAngleLeft`
  ).then(lazyIconImport)
)

const AngleDownIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faAngleDown`
  ).then(lazyIconImport)
)

const LockIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faLock`
  ).then(lazyIconImport)
)
const MobileIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faMobile`
  ).then(lazyIconImport)
)

const ExclamationIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faExclamationCircle`
  ).then(lazyIconImport)
)
const ExclamationTriangleIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faExclamationTriangle`
  ).then(lazyIconImport)
)

const CreditCardIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faCreditCard`
  ).then(lazyIconImport)
)

const SpinnerIcon = React.lazy(() =>
  import(
    /* webpackChunkName: "fortawesome" */
    `@fortawesome/pro-regular-svg-icons/faSpinner`
  ).then(lazyIconImport)
)

const suspenseIcon = (Icon) => {
  return (
    <Suspense fallback={<></>}>
      <Icon />
    </Suspense>
  )
}

export const ICONS: IconsProps = {
  ALERTS: suspenseIcon(AlertsIcon),
  SIGNOUT: suspenseIcon(SignOutIcon),
  TIMES: suspenseIcon(TimesIcon),
  GLOBE: suspenseIcon(GlobeIcon),
  USER: suspenseIcon(UserIcon),
  ANGLELEFT: suspenseIcon(AngleLeftIcon),
  ANGLEDOWN: suspenseIcon(AngleDownIcon),
  LOCK: suspenseIcon(LockIcon),
  EXCLAMATION: suspenseIcon(ExclamationIcon),
  CREDIT_CARD: suspenseIcon(CreditCardIcon),
  MOBILE: suspenseIcon(MobileIcon),
  EXCLAMATION_TRIANGLE: suspenseIcon(ExclamationTriangleIcon),
  SPINNER: suspenseIcon(SpinnerIcon),
  BUILDING_COLUMNS: suspenseIcon(BuildingColumnsIcon)
}
