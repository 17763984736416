import type { AnyObject } from 'syf-js-utilities/typings/AnyObject'
import type { ErrorResponseData } from 'typings/ErrorResponseData'

/**
 * Checks if the passed object is a business error response.
 * Used for type narrowing functions that can return this json data
 * or null from a response.
 * @param response - the parsed response data (json) coming from a fetch function or a raw response
 * @returns boolean - whether it is ErrorResponseData or not
 */
function isBusinessErrorData(
  response: AnyObject
): response is ErrorResponseData {
  return response?.code === 'identity.businessError'
}

export default isBusinessErrorData
