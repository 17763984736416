import { Suspense, useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { isMockData } from 'syf-js-utilities'
import routes from 'const/routes'
import { startAuthentication } from 'helpers/authUtils'
import { useUser } from 'hooks'
import LoadingView from 'ui/molecules/LoadingView'
import config from 'const/config'
import { authFlow as authFlowKeyName } from 'const/session'
import { QUERY_PARAMS } from 'const/queryParams'
import setAuthFlow from 'helpers/setAuthFlow'
import { setNewRelicAttribute } from 'helpers/newRelic'
import checkStoredToken from 'helpers/checkStoredToken/checkStoredToken'
import { UNIVERSAL_ACTIVATION_FLOW } from 'const/constants'
import type { AuthorizedSignedInCheckProps } from './AuthorizedSignedInCheck.types'

const { APP_ENV } = config

/**
 * Component to check authorization state and signed in state, redirecting
 * to global auth if not authorized. If already signed in, redirect to the
 * signed in route. This specifically checks for an id token from /authorize,
 * NOT an access token. RequiresAuth checks for the access token.
 * Compatible with React.lazy dynamic imports for the children
 *
 * @param {AuthorizedSignedInCheckProps} props - Component props containing:
 * @param {ReactElement} props.children - what to render if authorized but not signed in, dynamic import allowed
 * @param {AuthFlowType} props.authFlow - Optional, the specific type of signin page and flow user will be directed to
 * @param {Function} [props.authorizer=startAuthentication] - Optional, props for the requiresAuth component
 * @param {string} [props.signedInRoute=routes.DASHBOARD] - Optional, route to redirect to if user is signed in already
 * @returns {ReactElement} - The signin page or a redirect to another page
 */
const AuthorizedSignedInCheck = ({
  children,
  authFlow,
  authorizer = startAuthentication,
  signedInRoute = routes.DASHBOARD
}: AuthorizedSignedInCheckProps) => {
  const { isLoggedIn } = useUser()
  const [searchParams] = useSearchParams()
  const isUniversalActivation = authFlow === UNIVERSAL_ACTIVATION_FLOW

  // introspect call only possible if we have an id token from /authorize
  const authorizeToken = searchParams.get(QUERY_PARAMS.idToken)
  const notAuthorizedYet = !authorizeToken

  // check for the mockData queryParam/sessionStore value in nonprod to disable global auth redirect
  const isMocked = isMockData(APP_ENV)

  // only need authorization if they don't have an access token
  const notAuthenticatedAlready = !checkStoredToken()

  useEffect(() => {
    /*
     * if there's no id token, we need to get one from global auth /authorize
     * so our session is authorized and we can start authenticating
     * the user with sign in flow
     */
    if (
      notAuthorizedYet &&
      notAuthenticatedAlready &&
      !isMocked &&
      !isUniversalActivation
    ) {
      authorizer()
    }
  }, [authorizeToken])

  if (authFlow) {
    setAuthFlow(authFlow)
    setNewRelicAttribute(authFlowKeyName, authFlow)
  }

  /*
   * first so it redirects if signed in before anything,
   * otherwise the other renders will keep the redirect from happening
   */
  if (isLoggedIn) {
    // leading slash so we go to the route off the base url
    return <Navigate to={`/${signedInRoute}`} />
  }

  // fallback while waiting for the /authorize call so no /introspect goes out and shows an error page
  if (notAuthorizedYet && !isUniversalActivation) {
    return <LoadingView showText={false} />
  }

  return (
    <Suspense fallback={<LoadingView showText={false} />}>{children}</Suspense>
  )
}

export default AuthorizedSignedInCheck
