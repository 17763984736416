import { ReactElement } from 'react'
import {
  MOCK_ACCOUNT_NOT_FOUND,
  MOCK_AUTH,
  MOCK_AUTHENTICATE_IOVATION,
  MOCK_AUTH_POST_ERROR,
  MOCK_AUTOPAY_ENROLLED,
  MOCK_BLOCKED_ACCOUNT,
  MOCK_CLIENT_ERROR,
  MOCK_EXPIRED_TOKEN,
  MOCK_INVALID_TOKEN,
  MOCK_NO_ACTIVE_ACCOUNTS,
  MOCK_SESSION_INVALID,
  MOCK_SESSION_NOT_FOUND,
  MOCK_THROW_MFE,
  MOCK_TOKEN_SERVICE_ERROR
} from 'mocks/ui/const/toggles'
import { CARDS_LIMIT_MSG } from 'mocks/ui/const/mockValidationMsg'
import { MOCK_CARDS_LIMIT } from 'mocks/ui/const/mockInputs'
import ThrowsErrorCheckbox from 'ui/atoms/ThrowsError/ThrowsErrorCheckbox'
import EnableMSWCheckbox from 'mocks/ui/ToggleMSW'
import ToggleMockCheckbox from 'mocks/ui/molecules/ToggleMockCheckbox'
import MockInput from 'mocks/ui/molecules/MockInput'
import {
  authenticateErrorBlockedAccountPostHandler,
  authenticateErrorIovationPostHandler,
  authenticateErrorSessionInvalidPostHandler,
  authenticateErrorSessionNotFoundPostHandler,
  autopayStatusEnrolledGetHandler,
  tokenServicePostErrorHandler
} from 'mocks/handlers'
import MockTogglesProvider from 'mocks/hooks/useMockToggles/MockTogglesContext'
import { MOCK_TRIGGER_TOOL_TIPS } from 'mocks/ui/const/mockTriggerToolTips'
import { cardsLimitOnChange } from 'mocks/ui/molecules/MockInput/MockInputUtils'
/**
 * MockToggles is composed of all the mocking toggle button components to trigger custom mock handlers
 * @param props
 * @returns Mocking toggle buttons
 */
const MockToggles = (): ReactElement => {
  const {
    INVALID_SESSION,
    SESSION_NOT_FOUND,
    INVALID_TOKEN,
    EXPIRE_TOKEN,
    CLIENT_ERROR,
    AUTH_POST_ERROR,
    AUTOPAY_ENROLLED,
    ACC_NOT_FOUND,
    NO_ACTIVE_ACCOUNTS,
    CARDS_LIMIT,
    SECURED_ACC_BLOCKED
  } = MOCK_TRIGGER_TOOL_TIPS

  return (
    <MockTogglesProvider>
      <h3>General</h3>
      <EnableMSWCheckbox />
      <ThrowsErrorCheckbox />
      <h3>Authentication</h3>
      <ToggleMockCheckbox
        name={MOCK_SESSION_INVALID}
        mswOverrideHandlers={authenticateErrorSessionInvalidPostHandler}
        toolTip={INVALID_SESSION}
      />
      <ToggleMockCheckbox
        name={MOCK_SESSION_NOT_FOUND}
        mswOverrideHandlers={authenticateErrorSessionNotFoundPostHandler}
        toolTip={SESSION_NOT_FOUND}
      />
      <ToggleMockCheckbox name={MOCK_INVALID_TOKEN} toolTip={INVALID_TOKEN} />
      <ToggleMockCheckbox name={MOCK_EXPIRED_TOKEN} toolTip={EXPIRE_TOKEN} />
      <ToggleMockCheckbox name={MOCK_CLIENT_ERROR} toolTip={CLIENT_ERROR} />
      <ToggleMockCheckbox name={MOCK_AUTH} />
      <ToggleMockCheckbox
        name={MOCK_AUTH_POST_ERROR}
        toolTip={AUTH_POST_ERROR}
      />
      <ToggleMockCheckbox
        name={MOCK_ACCOUNT_NOT_FOUND}
        toolTip={ACC_NOT_FOUND}
      />
      <ToggleMockCheckbox
        name={MOCK_NO_ACTIVE_ACCOUNTS}
        toolTip={NO_ACTIVE_ACCOUNTS}
      />
      <ToggleMockCheckbox
        name={MOCK_BLOCKED_ACCOUNT}
        mswOverrideHandlers={authenticateErrorBlockedAccountPostHandler}
        toolTip={SECURED_ACC_BLOCKED}
      />
      <h3>Errors</h3>
      <ToggleMockCheckbox
        name={MOCK_AUTHENTICATE_IOVATION}
        mswOverrideHandlers={authenticateErrorIovationPostHandler}
      />
      <ToggleMockCheckbox
        name={MOCK_TOKEN_SERVICE_ERROR}
        mswOverrideHandlers={tokenServicePostErrorHandler}
      />
      <ToggleMockCheckbox name={MOCK_THROW_MFE} />
      <h3>Payments</h3>
      <ToggleMockCheckbox
        name={MOCK_AUTOPAY_ENROLLED}
        toolTip={AUTOPAY_ENROLLED}
        mswOverrideHandlers={autopayStatusEnrolledGetHandler}
      />
      <h3>Customize Mock Data</h3>
      <MockInput
        name={MOCK_CARDS_LIMIT}
        toolTip={CARDS_LIMIT}
        validationMsg={CARDS_LIMIT_MSG}
        defaultValue="3"
        onChange={cardsLimitOnChange}
      />
    </MockTogglesProvider>
  )
}

export default MockToggles
