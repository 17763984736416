import { UNIVERSAL_ACTIVATION_ROUTE_FLAG } from 'const/flags/globals'
import { Outlet } from 'react-router-dom'
import { useFeatures } from 'flagged'
import MaintenancePage from 'ui/pages/MaintenancePage'
import { UNIVERSAL_ACTIVATION_FLOW } from 'const/constants'
import AuthorizedSignedInCheck from '../AuthorizedSignedInCheck'
import QueryParamsCheck from '../QueryParamsCheck'

const UniversalActivationCheck = () => {
  const featureFlags = useFeatures()
  const isFeatureFlagEnabled = featureFlags[UNIVERSAL_ACTIVATION_ROUTE_FLAG]

  return (
    <>
      {isFeatureFlagEnabled ? (
        <QueryParamsCheck authFlow={UNIVERSAL_ACTIVATION_FLOW}>
          <AuthorizedSignedInCheck authFlow={UNIVERSAL_ACTIVATION_FLOW}>
            <Outlet />
          </AuthorizedSignedInCheck>
        </QueryParamsCheck>
      ) : (
        <MaintenancePage />
      )}
    </>
  )
}

export default UniversalActivationCheck
