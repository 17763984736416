import { DefaultAnalyticsObject } from 'helpers/analytics/analytics.types'

export const PEAKS_ANALYTICS_DEFAULTS: DefaultAnalyticsObject = {
  ClientName: 'generic',
  CustomerType: 'consumer',
  ErrorMessage: '',
  MessageType: '',
  PageFunction: 'quickaccess',
  PageKind: '',
  pageName: '',
  PageSubFunction: '',
  PNL: 'peaks',
  Product: 'generic',
  SiteIdentifier: 'peaks'
}

export const SECURED_INSTALLMENTS_DEFAULT_PRODUCT = 'INSTALLMENT_LOAN_SECURED'
export const PAYLATER_DEFAULT_PRODUCT = 'PAY_LATER'
