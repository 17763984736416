import { ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getQueryParam } from 'syf-js-utilities'
import routes from 'const/routes'
import Loader from 'authorization-module/ui/atoms/Loader'
import tokenName from 'const/session'
import checkStoredToken from 'helpers/checkStoredToken/checkStoredToken'

export interface RequiresAuthProps {
  /** Callback function that starts auth flow for an unauthenticated user, redirecting to the auth server */
  authorizeCallback: () => void
  /** Callback function to start app for authenticated users, such as redirecting to logged in homepage or setting logged in state */
  authenticatedCallback?: () => void
  /** If using mock data then authenticatedCallback is forced to run */
  isMockData?: boolean
  /** Child components to render when authenticated */
  children: ReactElement
}

/**
 * Wrapper component that conditionally renders children
 * depending on whether user is authenticated with an access token or not.
 * This specifically looks for an access token, NOT an id token from /authorize
 * @param props {@link RequiresAuthProps}
 * @param props.authorizeCallback Callback function that starts auth flow for an unauthenticated user, redirecting to the auth server
 * @param props.authenticatedCallback Callback function to start app for authenticated users, such as redirecting to logged in homepage or setting logged in state
 * @param props.isMockData If using mock data then authenticatedCallback is forced to run
 * @param props.children Child components to render when authenticated
 * @returns {ReactElement}
 */
const RequiresAuth = ({
  authorizeCallback,
  authenticatedCallback = () => {
    // this is intentional
  },
  isMockData,
  children
}: RequiresAuthProps): ReactElement => {
  // prevent access token collisions from other platforms
  const hasValidToken = checkStoredToken()
  const isAuthorized = hasValidToken || isMockData
  // for forcing the login page to show, without idToken from the auth server
  const isForcedLogin = getQueryParam('forceLogin')

  const navigateTo = useNavigate()

  // auth check and callbacks run only once on mount
  useEffect(() => {
    if (isForcedLogin) {
      navigateTo(routes.LOGIN)
      // early return so we don't redirect to auth server
      return
    }
    if (isAuthorized) {
      authenticatedCallback()
    } else {
      // ensures any non-peaks access tokens don't conflict with our auth flow
      sessionStorage.removeItem(tokenName)
      authorizeCallback()
    }
  }, [])

  return isAuthorized ? children : <Loader />
}

export default RequiresAuth
