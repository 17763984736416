import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import PageContainer from 'ui/pages/Pages.subcomponents'
import {
  fontSizes,
  lineHeights,
  spacing
} from 'syf-component-library/defaultTheme'
import { H5 } from 'syf-component-library/ui/typography'
import { Inset } from 'syf-component-library/ui/spacing'

export const Container = styled(PageContainer)`
  margin: ${spacing.small}px auto;

  @media ${query.greaterThanMedium} {
    max-width: 1200px;
  }

  * {
    box-sizing: border-box;
  }
`
export const TextContainer = styled(Inset)`
  padding: ${spacing.large}px 56px ${spacing.large}px 56px;

  @media ${query.lessThanSmall} {
    padding: ${spacing.large}px;
  }
`

export const InnerContainer = styled.div`
  text-align: center;
  width: 100%;
  position: relative;
`

export const MaintenancePageHeader = styled.div`
  background-color: ${({ theme }) => theme.syfYellow};
  height: 121px;
  width: 100%;

  @media ${query.greaterThanMedium} {
    height: 232px;
  }
`

export const SupportText = styled(H5)`
  font-size: ${fontSizes.root}px;
  line-height: ${lineHeights.root};
`

export const CardImage = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  overflow: hidden;
  height: 131px;
  top: 3px;

  @media ${query.greaterThanMedium} {
    height: 250px;
    top: 6px;
  }
`
