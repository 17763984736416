interface SignInErrorTypeProps {
  SECURED_INSTALLMENTS: string
  PAY_LATER: string
}

export const signInErrorTypes: SignInErrorTypeProps = {
  SECURED_INSTALLMENTS: 'idp.secured-login.account.not-found',
  PAY_LATER: 'idp.pii.authenticate.account.not-found'
}
export const signInErrors = [
  signInErrorTypes.SECURED_INSTALLMENTS,
  signInErrorTypes.PAY_LATER
]
