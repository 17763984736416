/* eslint-disable no-console */
import { isMockData } from 'syf-js-utilities'
// have to direct import or jest errors on the import
import redirectToAuth from 'authorization-module/helpers/redirectToAuth'
import formatAuthRequest from 'authorization-module/helpers/formatAuthRequest'
import combineUrlAndParams from 'authorization-module/helpers/combineUrlAndParams'
import config from 'const/config'
import tokenName, { appRegion, authDomain, sessionIdName } from 'const/session'
import { MOCK_AUTH } from 'mocks/ui/const/toggles'
import { mockAuthData } from 'mocks/mockData'
import { getAppSessionItem, setAppSessionItem } from './appSessionItem'
import { getSessionTokenAccounts } from './sessionTokenAccounts'
import { setSelectedAccount } from './setSelectedAccount'
import { getAuthScopes } from './authScopes'
import { getApigeeClientId } from './getApigeeClientId'
import { getTargetOrigin } from './getTargetOrigin'

const {
  APP_ENV,
  AUTH_DOMAIN,
  // where we convert auth code to access token
  CALLBACK_PAGE: finishAppAuthenticationURL
} = config

/**
 * Gets the auth access token stored in session storage.
 * @returns the access token string
 */
export const getSessionAccessToken = (): string => getAppSessionItem(tokenName)

/**
 * Sets the auth access token value in session storage
 * @param value access token string for api requests
 */
export const setSessionAccessToken = (value: string): void =>
  setAppSessionItem(tokenName, value)

/**
 * Gets the auth session id stored in session storage.
 * No prefix to make session id cross trackable.
 * @returns the session id string
 */
export const getSessionID = (): string => getAppSessionItem(sessionIdName, '')

/**
 * Sets the auth session id value in session storage.
 * @param value session id / correlation id / interaction id string for api requests
 */
export const setSessionID = (value: string): void =>
  // empty prefix to make session id cross trackable
  setAppSessionItem(sessionIdName, value, '')

/**
 * Function to simulate the authorization module's redirectToMockAuth function
 * Takes in all the same args and does a window replace redirect just like
 * the global auth server would, passing a mock id token
 * @param {string} baseURL Global auth server domain
 * @param {string} clientId Apigee developer app id
 * @param {string} redirectURI Consumer application callback url
 * @param {string} loginHintURL IDP login page url
 * @param {string} [scope] An optional param - Auth token scope
 * @param {string} [navigateTo] An optional param - consumer application sends the user to either home or different path ex., deep linking
 */
export const redirectToMockAuth = (
  baseURL: string,
  clientId: string,
  redirectURI: string,
  loginHintURL: string,
  scope?: string,
  navigateTo?: string
): void => {
  // using the actual auth module flow to generate the appropriate session values
  const { oauthRedirectUri, requestBodyQueryParams } = formatAuthRequest({
    baseURL,
    clientId,
    redirectURI,
    loginHintURL,
    scope,
    navigateTo
  })
  const mockRedirectURL = combineUrlAndParams(
    oauthRedirectUri,
    requestBodyQueryParams
  )
  console.log(
    `This is where we would have redirected to for OAuth: ${mockRedirectURL}`
  )
  console.log(
    `Auth server would process the request and 302 redirect to a login page with a idToken: ${loginHintURL}`
  )
  // just like the auth server, do a window location replace type redirect
  window.location.replace(mockAuthData.authorizeLoginURL)
}

/**
 * Redirects to auth server using the authorization-module's process flow
 * unless the mockData option is set in the query string params.
 * @returns na
 */
export const startAuthentication = (): void => {
  if (isMockData(APP_ENV)) {
    return
  }
  // store for reference by mfes that need to confirm which auth server was called
  setAppSessionItem(authDomain, config.AUTH_DOMAIN)

  // store for reference by mfes to determine which API to consume
  setAppSessionItem(appRegion, config.APP_REGION)

  // for IVA integration
  const defaultAccount = getSessionTokenAccounts()[0]
  setSelectedAccount(defaultAccount)

  // retrieve auth config values corresponding to current auth flow
  const scope = getAuthScopes()
  const apigeeAppAPIKey = getApigeeClientId()
  const loginPageURL = getTargetOrigin()

  const isMockingAuth = getAppSessionItem(MOCK_AUTH) === 'true'
  if (isMockingAuth) {
    redirectToMockAuth(
      AUTH_DOMAIN,
      apigeeAppAPIKey,
      finishAppAuthenticationURL,
      loginPageURL,
      scope
    )
    return
  }

  // send user to auth server to start authenticating
  redirectToAuth(
    AUTH_DOMAIN,
    apigeeAppAPIKey,
    finishAppAuthenticationURL,
    loginPageURL,
    scope
  )
}
