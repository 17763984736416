import { ReactElement, useEffect } from 'react'
import { useSettings } from 'hooks'
import { CheckBoxWrap } from 'ui/App/AppWrapper.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { startMSW } from 'mocks/mswBrowser'
import config from 'const/config'

const EnableMSWCheckbox = (): ReactElement => {
  const { isMockDataEnabled, toggleMockData } = useSettings()

  const { APP_ENV } = config
  const isProdOrUAT =
    APP_ENV === 'production' ||
    window.location.hostname === 'uat.synchronycredit.com'

  const handleClick = () => {
    toggleMockData()
  }

  useEffect(() => {
    if (isMockDataEnabled && !isProdOrUAT) {
      startMSW()
    }
  }, [isMockDataEnabled])
  return (
    <CheckBoxWrap
      isChecked={isMockDataEnabled}
      onChange={handleClick}
      icon={isMockDataEnabled && <FontAwesomeIcon icon={faCheck} />}
    >
      {`${isMockDataEnabled ? 'Turn Off' : 'Turn On'} Mock Data`}
    </CheckBoxWrap>
  )
}

export default EnableMSWCheckbox
