/**
 *  Taking this to simulate how the response would be
 *  source: https://bitbucket.glb.syfbank.com/projects/EUI/repos/card-actions-mfe/browse/src/sandbox/mocks/data/mockPreLoginCardActivation.ts
 */
interface BusinessError {
  code: string
  message: string
}

interface GenericErrorMockData {
  code: string
  message: string
  trackingId: string
  transient: boolean
  businessErrors: BusinessError[]
}

const activationExternalStatusErrorMock: GenericErrorMockData = {
  code: 'activation.businessError',
  message: 'Encountered Business Error',
  trackingId: 'fbd9fc90-c43c-44f5-9511-a79d83411a08',
  transient: false,
  businessErrors: [
    {
      code: '610004',
      message: 'External status code does not allow Account Activation'
    }
  ]
}

const activationSuccessResponse = {
  productDisplayName: 'lowes'
}

export { activationExternalStatusErrorMock, activationSuccessResponse }
