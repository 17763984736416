import { rest } from 'msw'
import { QA_API_HOST, sandboxApi } from 'const/config'
import { testEndpointUrl } from 'services/testQuery'
import {
  AccountTypeMapping,
  accountActivityUrl,
  accountSummaryV2GetHandler as accountSummaryV2GetHandlerPaylater,
  autopayStatusUrl,
  creditAccountsQueryGetHandler
} from 'syf-api'
import {
  accountFeaturesUrl,
  paymentEnterpriseEligibilityUrl,
  paymentMethodsUrl,
  paymentQueryUrl,
  settlementEligibilityUrl,
  statementsUrl
} from 'syf-api/api/endpoints'
import mockAuthData from 'mocks/mockData/auth/mockAuthData'
import { introspectURL } from 'services/introspectQuery/introspectQuery'
import {
  authenticateURL,
  paylaterAuthenticateURL
} from 'services/authenticateMutation/authenticateMutation.constants'
import { completeURL } from 'services/completeMutation/completeMutationOptions'
import { authorizeURL } from 'services/authorizeMutation/authorizeMutationOptions'
import { getMockAccountIds } from 'mocks/mockData'
import { SECURED_INSTALLMENTS_FLOW } from 'const/constants'
import getSessionAuthFlow from 'helpers/authFlows/getSessionAuthFlow'
import { mockProfileData } from './mockData/profile/mockProfileData'
import { mockAlertData } from './mockData/alerts/mockAlertsData'
import {
  mockAccountFeatures,
  mockActivity,
  mockAutopayStatus,
  mockAutopayStatusEnrolled,
  mockMMAConfig,
  mockPaymentEnterpriseEligibility,
  mockPaymentMethods,
  mockSettlementEligibility,
  mockStatements
} from './mockData'
import { mockPreferencesData } from './mockData/alerts/mockPreferencesData'
import accountSummarySecuredInstallmentsMock from './mockData/accountSummary/securedInstallments'
import {
  activationExternalStatusErrorMock,
  activationSuccessResponse
} from './mockData/activation/mockActivationData'

/** Whether the environment is a browser or test runner */
export const isBrowserEnvironment = process.env.NODE_ENV !== 'test'

/**
 * Setup file for MSW (Mock Service Worker) to mock api calls
 * https://mswjs.io/docs/getting-started/mocks/rest-api
 */

/** Prefixes passed in path with sandboxApi value from config */
export const mswUrl = (path: string): string => new URL(path, sandboxApi).href

/** URLs requests to catch and mock */
export const mockUrls = {
  test: `${new URL(testEndpointUrl, sandboxApi)}`,
  // have to use a wildcard for auth module fetches to be caught
  token: '*/oauth2/token',
  introspect: `*/${introspectURL}`,
  piiAuthenticate: `*/${paylaterAuthenticateURL}`,
  authentication: `*/${authenticateURL}`,
  complete: `*/${completeURL}`,
  authorize: `*/${authorizeURL}`,
  // a config file that mma mfe requests
  mmaConfig: '*/prod-mma-config.json',
  // services
  accountActivity: `*/${accountActivityUrl}`,
  autopay: `*/${autopayStatusUrl}`,
  enterpriseEligibility: `*/${paymentEnterpriseEligibilityUrl}`,
  paymentMethods: `*/${paymentMethodsUrl}`,
  settlementEligibility: `*/${settlementEligibilityUrl}`,
  accountFeatures: `*/${accountFeaturesUrl}`,
  statements: `*/${statementsUrl}`,
  // for AlertsMFE
  profile: '*/credit/accounts/profile',
  alerts: '*/alerts',
  alertsPreferences: '*/alerts/preferences',
  summary: '*/credit/accounts/summary',
  cardsActivate: '*/cards/activate',
  paymentQuery: `*/${paymentQueryUrl}`
}

const accountTypeMapping: AccountTypeMapping = {
  '2ec7be46-00f2-4367-9ef8-acb42e3a7ca3': 'INSTALLMENT_LOAN_PAY_IN_FOUR',
  'i5k38m4g-0h0g-404g-h7jg-1851i58j7h3g': 'INSTALLMENT_LOAN_MONTHLY'
}

const hostUrl = sandboxApi

// special case response mockers

/** Mock error response to testQuery */
export const testErrorGetHandler = rest.get(
  mockUrls.test,
  (_request, response, ctx) => {
    return response(ctx.status(400), ctx.json(mockAuthData.test(false)))
  }
)

export const testNoContentPostHandler = rest.post(
  mockUrls.test,
  (_request, response, ctx) => {
    return response(ctx.status(204), ctx.json(''))
  }
)

export const tokenServicePostErrorHandler = rest.post(
  mockUrls.token,
  (_request, response, ctx) => {
    return response(ctx.status(422), ctx.json({}))
  }
)

export const tokenPostHandler = rest.post(
  mockUrls.token,
  (_request, response, ctx) => {
    const noActiveAccounts =
      sessionStorage.getItem('no_active_accounts') === 'true'

    return response(
      ctx.status(200),
      ctx.json({
        ...mockAuthData.token,
        accountIdentifier: noActiveAccounts ? [] : getMockAccountIds()
      })
    )
  }
)

export const tokenErrorPostHandler = rest.post(
  mockUrls.token,
  (_request, response, ctx) => {
    return response(ctx.status(500), ctx.json({}))
  }
)

export const introspectSlowPostHandler = rest.post(
  mockUrls.introspect,
  (_request, response, ctx) => {
    return response(
      ctx.delay(2000),
      ctx.status(200),
      ctx.json(mockAuthData.introspect)
    )
  }
)

export const authenticateErrorIovationPostHandler = rest.post(
  mockUrls.piiAuthenticate,
  (_request, response, ctx) => {
    return response(
      ctx.status(422),
      ctx.json(mockAuthData.authenticateErrorIovation)
    )
  }
)

export const authenticateErrorSessionInvalidPostHandler = rest.post(
  mockUrls.piiAuthenticate,
  (_request, response, ctx) => {
    return response(
      ctx.status(401),
      ctx.json(mockAuthData.authenticateErrorFailed)
    )
  }
)

export const authenticateErrorSessionNotFoundPostHandler = rest.post(
  mockUrls.piiAuthenticate,
  (_request, response, ctx) => {
    return response(
      ctx.status(422),
      ctx.json(mockAuthData.authenticateErrorSessionNotFound)
    )
  }
)

export const authenticateErrorBlockedAccountPostHandler = rest.post(
  mockUrls.authentication,
  (_request, response, ctx) => {
    return response(
      ctx.status(422),
      ctx.json(mockAuthData.authenticateErrorBlockedAccount)
    )
  }
)

export const accountSummaryV2HandlerQA = accountSummaryV2GetHandlerPaylater(
  { hostUrl: QA_API_HOST },
  accountTypeMapping
)

const accountSummaryV2GetHandler = () => {
  if (getSessionAuthFlow() === SECURED_INSTALLMENTS_FLOW) {
    return rest.get(mockUrls.summary, (_request, response, ctx) => {
      return response(
        ctx.status(200),
        ctx.delay(300),
        ctx.json(accountSummarySecuredInstallmentsMock())
      )
    })
  }
  return accountSummaryV2GetHandlerPaylater(
    { hostUrl, delayMs: isBrowserEnvironment ? 1500 : 0 },
    accountTypeMapping
  )
}

export const autopayStatusEnrolledGetHandler = rest.get(
  mockUrls.autopay,
  (_request, response, ctx) => {
    return response(
      ctx.status(200),
      ctx.delay(300),
      ctx.json(mockAutopayStatusEnrolled)
    )
  }
)

// default mock response mockers
export const testGetHandler = rest.get(
  mswUrl(mockUrls.test),
  (_request, response, ctx) => {
    const { url } = _request
    const isForceError = url.searchParams.get('error') === 'true'
    if (isForceError) {
      return response(ctx.status(400), ctx.json(mockAuthData.test(false)))
    }
    return response(ctx.status(200), ctx.json(mockAuthData.test()))
  }
)

export const introspectPostHandler = rest.post(
  mockUrls.introspect,
  (_request, response, ctx) => {
    const invalidToken = sessionStorage.getItem('invalid_token') === 'true'
    const expiredToken = sessionStorage.getItem('expired_token') === 'true'
    if (expiredToken) {
      // this fixes a potential infinite loop, if expired token is not reset after the page refreshes
      sessionStorage.setItem('expired_token', 'false')
      return response(
        ctx.status(200),
        ctx.json(mockAuthData.introspectExpiredToken)
      )
    }
    if (invalidToken) {
      // this fixes a potential infinite loop, if invalid token is not reset after the page refreshes
      sessionStorage.setItem('invalid_token', 'false')
      return response(
        ctx.status(200),
        ctx.json(mockAuthData.introspectInvalidToken)
      )
    }
    return response(ctx.status(200), ctx.json(mockAuthData.introspect))
  }
)

export const authenticatePostHandler = rest.post(
  mockUrls.authentication,
  (_request, response, ctx) => {
    const accountNotFound =
      sessionStorage.getItem('account_not_found') === 'true'
    if (accountNotFound) {
      return response(
        ctx.status(422),
        ctx.json(mockAuthData.authenticateErrorSecuredNotFound)
      )
    }
    return response(ctx.status(200), ctx.json(mockAuthData.authenticateSecured))
  }
)

export const piiAuthenticatePostHandler = rest.post(
  mockUrls.piiAuthenticate,
  (_request, response, ctx) => {
    const accountNotFound =
      sessionStorage.getItem('account_not_found') === 'true'
    if (accountNotFound) {
      return response(
        ctx.status(422),
        ctx.json(mockAuthData.authenticateErrorNotFound)
      )
    }
    return response(ctx.status(200), ctx.json(mockAuthData.authenticate))
  }
)

export const completeGetHandler = rest.get(
  mockUrls.complete,
  (_request, response, ctx) => {
    const clientIdErr = sessionStorage.getItem('client_error') === 'true'
    if (clientIdErr) {
      return response(ctx.status(422), ctx.json(mockAuthData.completeError))
    }
    return response(ctx.status(200), ctx.json(mockAuthData.complete))
  }
)
/** Used by mma-mfe, MAP to retrieve activity */
const accountActivityQueryGetHandler = rest.get(
  mockUrls.accountActivity,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockActivity))
  }
)

// some config obj for mma-mfe
const mmaConfigGetHandler = rest.get(
  mockUrls.mmaConfig,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockMMAConfig))
  }
)

const accountFeaturesHandler = rest.get(
  mockUrls.accountFeatures,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockAccountFeatures))
  }
)

const statementsHandler = rest.get(
  mockUrls.statements,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockStatements))
  }
)

/** Used by mma-mfe, MAP */
const autopayStatusGetHandler = rest.get(
  mockUrls.autopay,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockAutopayStatus))
  }
)

/** Used by MAP when autopay enrollment succeeds on pre 1st cycle acct */
const autopayStatusPutHandler = rest.put(
  mockUrls.autopay,
  (_request, response, ctx) => {
    return response(
      ctx.status(202),
      ctx.set('Content-Type', 'application/text'),
      ctx.text('')
    )
  }
)

/** Used by payment enterprise eligibility, MAP */
const enterpriseEligibilityGetHandler = rest.get(
  mockUrls.enterpriseEligibility,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockPaymentEnterpriseEligibility))
  }
)

/** Used by payment methods, MAP */
const paymentMethodsGetHandler = rest.get(
  mockUrls.paymentMethods,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockPaymentMethods))
  }
)
/** Used by payment plan settlement, MAP */
const settlementEligibilityGetHandler = rest.get(
  mockUrls.settlementEligibility,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockSettlementEligibility))
  }
)
/** Used by MAP */
const postPaymentHandler = rest.post(
  mockUrls.paymentQuery,
  (_request, response, ctx) => {
    const paymentId = '1234567'
    return response(ctx.status(200), ctx.json({ paymentId }))
  }
)

/** Used for Alerts MFE */
const profileGetHandler = rest.get(
  mockUrls.profile,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockProfileData))
  }
)

const alertsGetHandler = rest.get(
  mockUrls.alerts,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockAlertData))
  }
)

const alertsPreferenceGetHandler = rest.get(
  mockUrls.alertsPreferences,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockPreferencesData))
  }
)

const cardsActivateHandler = rest.put(
  mockUrls.cardsActivate,
  (_request, response, ctx) => {
    // using session storage to see what response should be sent
    const cardActivationFailed =
      sessionStorage.getItem('card_activation_failed') === 'true'

    const successResponse = response(
      ctx.status(200),
      ctx.json(activationSuccessResponse)
    )
    const errorResponse = response(
      ctx.status(422),
      ctx.json(activationExternalStatusErrorMock)
    )
    return cardActivationFailed ? errorResponse : successResponse
  }
)
// these handlers define the mock response to give for specific urls
const handlers = [
  introspectPostHandler,
  completeGetHandler,
  accountActivityQueryGetHandler,
  creditAccountsQueryGetHandler({ hostUrl }),
  accountSummaryV2GetHandler(),
  authenticatePostHandler,
  piiAuthenticatePostHandler,
  autopayStatusGetHandler,
  autopayStatusPutHandler,
  tokenPostHandler,
  enterpriseEligibilityGetHandler,
  paymentMethodsGetHandler,
  settlementEligibilityGetHandler,
  postPaymentHandler,
  mmaConfigGetHandler,
  statementsHandler,
  accountFeaturesHandler,
  profileGetHandler,
  alertsGetHandler,
  alertsPreferenceGetHandler,
  cardsActivateHandler
]

export default handlers
